/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.customPerview {
  border-radius: 10px;

  header {
    display: flex;
    padding-inline: 3rem;
    padding-block: 1.3rem;
    justify-content: space-between;
    align-items: center;

    .selectCatogery {
      button {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        border: none;
        border-radius: 10px;
        p {
          margin-bottom: 0;
          margin-inline: 1rem;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .headerLogo {
      width: 150px;
    }

    .searchInput {
      width: 40%;
      padding: 1rem;
      border-radius: 10px;

      .icon {
      }
      input {
        margin-left: 1rem;
        width: 90%;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    .nav {
      display: flex;
      align-items: center;

      p {
        margin-left: 1rem;
        padding-top: 1rem;
        font-size: 16px;
        transition: color 150ms ease-out 100ms;
      }
      button {
        margin-left: 1rem;
        padding: 1rem;
        border-radius: 10px;
        outline: none;
        border: none;
        transition: all 150ms ease-in-out 100ms;
      }
    }
  }

  main {
    padding: 3rem;

    .productCardContainer {
      display: flex;
      .productCard {
        padding: 1rem;
        border-radius: 10px;
        margin-inline: 1rem;

        button {
          padding: 0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          width: 100%;
          border: none;
          transition: all 150ms ease 100ms;
          div {
            padding: 0.5rem;
            border-radius: 10px;
            transition: all 150ms ease 100ms;
          }
          p {
            margin: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: all 150ms ease 100ms;
          }
        }

        .price {
          font-weight: 600;
          font-size: 18px;
        }

        .discount {
          font-size: 12px;
          text-decoration: line-through;
        }

        .productImage {
          width: 200px;
          position: relative;

          .imgDiscount {
            position: absolute;
            top: 1rem;
            right: 1rem;
            padding-inline: 8px;
            padding-block: 2px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }

  footer {
    padding: 3rem;
    h1 {
      font-size: 18px;
      margin-bottom: 1.5rem;
    }
    .emailInput {
      background-color: #f1f1f1;
      padding: 1rem;
      border-radius: 10px;
      display: flex;

      input {
        margin-left: 1rem;
        width: 90%;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    .footerContainer {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  }
}
